import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';

export enum ViewName {
  ArtistAlbum = 'artist-album',
  ArtistAlbums = 'artist-albums',
  ArtistDirectory = 'artist-directory',
  ArtistNews = 'artist-news',
  ArtistProfile = 'artist-profile',
  ArtistSimilar = 'artist-similar',
  ArtistSong = 'artist-song',
  ArtistSongs = 'artist-songs',
  Error404 = '404',
  Error500 = '500',
  Favorites = 'favorites',
  ForYou = 'home',
  GenreDirectory = 'genre-directory',
  GenrePage = 'genre-page',
  GenrePageNews = 'genre-news',
  LiveDirectory = 'live-directory',
  LiveLatest = 'live-latest',
  LiveProfile = 'live-profile',
  LiveSimilar = 'live-similar',
  NewsContentArticle = 'content-article',
  NewsDirectory = 'news-directory',
  NewsTopicDirectory = 'topic-directory',
  PlaylistDirectory = 'playlist-directory',
  PlaylistProfile = 'playlist-profile',
  PlaylistSubDirectory = 'playlist-sub',
  PodcastCategory = 'podcast-category',
  PodcastDirectory = 'podcast-directory',
  PodcastEpisode = 'podcast-episode',
  PodcastNetworkDirectory = 'network-directory',
  PodcastNews = 'podcast-news',
  PodcastProfile = 'podcast-profile',
  ProfileHistory = 'profile-history',
  ProfileMain = 'profile-main',
  ProfileSettings = 'profile-settings',
  ProfileSongs = 'profile-songs',
  AlexaLinkIOS = 'alexa-link-ios',
}

export const forYouPlayedFrom: {
  [key in ViewName]?: number;
} = {
  [ViewName.ArtistAlbum]: PLAYED_FROM.PROF_ALBUM_RAIL_RECS,
  [ViewName.ArtistAlbums]: PLAYED_FROM.PROF_ARTIST_RAIL_RECS,
  [ViewName.ArtistDirectory]: PLAYED_FROM.DIR_CUSTOM_MAIN_FEATURED_ARTISTS,
  [ViewName.ArtistNews]: PLAYED_FROM.PROF_ARTIST_RAIL_RECS,
  [ViewName.ArtistProfile]: PLAYED_FROM.PROF_ARTIST_RAIL_RECS,
  [ViewName.ArtistSimilar]: PLAYED_FROM.PROF_ARTIST_RAIL_RECS,
  [ViewName.ArtistSong]: PLAYED_FROM.PROF_SONG_RAIL_RECS,
  [ViewName.ArtistSongs]: PLAYED_FROM.PROF_ARTIST_RAIL_RECS,
  [ViewName.Favorites]: PLAYED_FROM.PROF_FAVORITES_RAIL_RECS,
  [ViewName.ForYou]: undefined,
  [ViewName.GenreDirectory]: PLAYED_FROM.DIR_GENRE_RAIL_RECS,
  [ViewName.GenrePage]: PLAYED_FROM.PROF_GENRE_RAIL_RECS,
  [ViewName.GenrePageNews]: PLAYED_FROM.PROF_GENRE_RAIL_RECS,
  [ViewName.LiveDirectory]: PLAYED_FROM.DIR_LIVE_FILTERED,
  [ViewName.LiveLatest]: PLAYED_FROM.PROF_LIVE_RAIL_RECS,
  [ViewName.LiveProfile]: PLAYED_FROM.PROF_LIVE_RAIL_RECS,
  [ViewName.LiveSimilar]: PLAYED_FROM.PROF_LIVE_RAIL_RECS,
  [ViewName.NewsContentArticle]: PLAYED_FROM.PROF_NEWS_RAIL_RECS,
  [ViewName.NewsDirectory]: PLAYED_FROM.PROF_NEWS_RAIL_RECS,
  [ViewName.NewsTopicDirectory]: PLAYED_FROM.PROF_NEWS_RAIL_RECS,
  [ViewName.PlaylistDirectory]: PLAYED_FROM.DIR_PLAYLIST_RAIL_RECS,
  [ViewName.PlaylistProfile]: undefined,
  [ViewName.PlaylistSubDirectory]: PLAYED_FROM.DIR_PLAYLIST_RAIL_RECS,
  [ViewName.PodcastCategory]: PLAYED_FROM.DIR_PODCAST_FILTERED_RAIL_RECS,
  [ViewName.PodcastDirectory]: PLAYED_FROM.DIR_PODCAST_RAIL_RECS,
  [ViewName.PodcastEpisode]: PLAYED_FROM.PROF_EPISODE_RAIL_RECS,
  [ViewName.PodcastNetworkDirectory]: PLAYED_FROM.DIR_PODCAST_RAIL_RECS,
  [ViewName.PodcastNews]: PLAYED_FROM.PROF_PODCAST_RAIL_RECS,
  [ViewName.PodcastProfile]: PLAYED_FROM.PROF_PODCAST_RAIL_RECS,
  [ViewName.ProfileHistory]: PLAYED_FROM.PROF_USER_RAIL_RECS,
  [ViewName.ProfileMain]: PLAYED_FROM.PROF_USER_RAIL_RECS,
  [ViewName.ProfileSettings]: PLAYED_FROM.PROF_USER_RAIL_RECS,
  [ViewName.ProfileSongs]: PLAYED_FROM.PROF_USER_RAIL_RECS,
};

export const forYouItemSelectedLocation: {
  [key in ViewName]?: string;
} = {
  [ViewName.LiveProfile]: 'live_profile|for_you|right_rail',
  [ViewName.PodcastProfile]: 'podcast_profile|for_you|right_rail',
};
